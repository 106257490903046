import React, { Fragment } from 'react';

const Brand = ({ mobile, src, company1, company2, alt }) => {
    return (
        <Fragment>
            <a href="/">
                <img src={src} className="logo" alt={alt} />
            </a>
            <div className={`company ${(mobile)?'':'ml-2'}`}>
                <p>{company1}</p>
                <p>{company2}</p>
            </div>
        </Fragment>
    )
}

export default Brand;