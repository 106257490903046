import React from 'react';
/* React Bootstrap */
import { Button } from 'react-bootstrap';
/* React Fontawesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const IconButton = ({ variant, className, href, target, onClick, icon, text }) => {
    return (
        <Button 
            variant={variant}
            className={className}
            href={href}
            target={target}
            onClick={onClick}
        >
            <span className="d-flex align-items-center">
                <FontAwesomeIcon icon={icon} />
                <span className={(text)?'ml-2':''}>{text}</span>
            </span>
        </Button>
    );
};

export default IconButton;