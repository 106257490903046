/* 
    banner：
    Services：服務項目
    Cases：工程實例
    Filter：篩選
*/
import { combineReducers } from 'redux';
import banners from './banners';
import licenses from './licenses';
import services from './services';
import cases from './cases';

const WebApp = combineReducers({
    banners,
    licenses,
    services,
    cases
});

export default WebApp;