/*
    設定黨
    apiHost: API 的 Http 網址
 */
// 本機設定
// const apiHost = 'http://localhost:3020';

// Server設定
const apiHost = 'https://sumhouse.tw:3020';


export { apiHost }