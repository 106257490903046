import React from 'react'
/* React Fontawesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const Accordion = ({ data, currentKey, handleToggle }) => {
    return (
        <div className="accordion">
            {data.map((item,index)=>(
                <div className="accordion-item" key={index} >
                    <div 
                        className={`accordion-toggle ${(currentKey===index)?'active':''}`} 
                        onClick={()=>handleToggle(index)}
                    >
                        <span>{item.question}</span>
                        <span>
                            <FontAwesomeIcon icon={faChevronRight} className="chevron" />
                        </span>
                    </div>
                    <div className={`accordion-collapse ${(currentKey===index)?'show':''}`}>
                        <p>{item.answer}</p>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default Accordion;