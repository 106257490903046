import React from 'react';
/* React Bootstrap */
import { Row, Col, Card } from 'react-bootstrap';
/* React Responsive Carousel */
import { Carousel } from 'react-responsive-carousel';
/* Constants */
import {
    TOTAL_DATA1,
    TOTAL_DATA2,
    CASE_TIME,
    CASE_ITEM,
    CASE_AREA
} from '../../../constants';

const CaseList = ({ data, handleClick }) => {
    console.log("data=",data);
    return (
        <Row>
            <Col lg={12} className="total-count text-gray">
                {TOTAL_DATA1}{data.length}{TOTAL_DATA2}
            </Col>
            {data.map((item,i) => (
                <Col lg={4} md={6} key={i}>
                    <Card className="mb-3">
                        <Carousel showThumbs={false} showIndicators={false}>
                            { Object.keys(item.img).map((i) => {
                                return (
                                    <div key={i}>
                                        <img src={item.img[i].src}  alt="img" />
                                    </div>
                                )
                            })}
                        </Carousel>
                        <Card.Body>
                            <Card.Title onClick={() => handleClick(item.id)}>
                                <h5>{item.name}</h5>
                            </Card.Title>
                            <Card.Text>
                                {CASE_TIME}：{item.time}<br/>
                                {/* {CASE_TIME}：{item.time}<br/> */}
                                {CASE_ITEM}：{item.service}<br/>
                                {CASE_AREA}：{item.area}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            ))}
        </Row>
    )
}

export default CaseList;