import React from 'react';
/* React Router */
import { useHistory } from 'react-router-dom'
/* React Bootstrap */
import { Container, Row, Col, Button } from 'react-bootstrap';
/* CSS */
import './not-found.css';

const NotFound = () => {
    const history = useHistory()
    return (
        <section className="not-found">
            <Container>
                <Row className="justify-content-center align-items-center text-center py-5">
                    <Col lg={12}>
                        <h1 className="text-blue">404</h1>
                        <p className="text-gray mb-4">查無此頁面</p>
                        <Button variant="lightblue" onClick={()=>history.push('/')}>返回首頁</Button>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default NotFound;