import React from 'react';
/* React Router */
import { Link } from 'react-router-dom';
/* React Bootstrap */
import { Row, Col, Breadcrumb } from 'react-bootstrap';
/* React Fontawesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
/* Constants */
import {
    ALL_CASE,
    ALL_CASE_PATH
} from '../../constants';

const ServiceBreadcrumb = ({ company, filter }) => {
    return (
        <Row className="pb-3">
            <Col>
                <Breadcrumb>
                    <li className="breadcrumb-item">
                        <Link to={`/case/categories/${ALL_CASE_PATH}`}>
                            <FontAwesomeIcon icon={faHome} className="mr-2" />{ALL_CASE}
                        </Link>
                    </li>
                    {(company.id)?
                        <li className="breadcrumb-item">
                            <Link to={`/case/categories/${company.id}`}>
                                {company.name}
                            </Link>
                        </li>
                    :''}
                    {(filter.id)?
                        <li className="breadcrumb-item">
                            <Link to={`/case/categories/${filter.id}`}>
                                {filter.name}
                            </Link>
                        </li>
                    :''}
                </Breadcrumb>
            </Col>
        </Row>
    )
}

export default ServiceBreadcrumb;