import React, { useState } from 'react';
/* React Bootstrap */
import { Container } from 'react-bootstrap';
/* Components */
import Brand from './brand';
import Menu from './menu';
/* React Fontawesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
/* CSS */
import './header.css';
/* Assets */
import LogoImg from '../../assets/logo.jpg';
/* Constants */
import { 
    COMPANY_NAME_1,
    COMPANY_NAME_2,
    COMPANY_SHORT_FORM_1,
    COMPANY_SHORT_FORM_2
} from '../../constants';

const Header = ({ data }) => {
    const [isNavToggle, setNavToggle] = useState(false);
    const hendleNavToggle = () => setNavToggle(!isNavToggle);
    //console.log("header -> data=",data)
    return (
        <header className="header fixed-top">
            {/* Menu */}
            <Container className="d-flex align-items-center">
                <div className="d-flex align-items-center mr-auto">
                    <Brand 
                        mobile={false} 
                        src={LogoImg} 
                        company1={COMPANY_NAME_1}
                        company2={COMPANY_NAME_2}
                        alt="sunhouse-waterproof-logo" />
                </div>
                <Menu
                    className="nav-menu d-none d-md-block"
                    mobile={false}
                    data={data}
                />
                <button className="nav-toggle d-md-none" onClick={hendleNavToggle}>
                    <FontAwesomeIcon icon={faBars}/>
                </button>
            </Container>
            {/* Mobile Menu */}
            <div className="d-md-none">
                <div className={`mobile-nav-overlay ${(isNavToggle)?"active":""}`} onClick={hendleNavToggle} />
                <div className={`mobile-nav-wrap ${(isNavToggle)?"active":""}`}>
                    <Brand 
                        mobile={true} 
                        src={LogoImg} 
                        company1={COMPANY_SHORT_FORM_1}
                        company2={COMPANY_SHORT_FORM_2}
                        alt="sunhouse-waterproof-logo" />
                    <Menu
                        className="mobile-nav-menu mt-3"
                        mobile={TextTrackCue}
                        data={data}
                        handleClick={hendleNavToggle}
                    />
                </div>
            </div>
        </header>
    )
}

export default Header;