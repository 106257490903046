import React from 'react';
/* React Bootstrap */
import { Container, Row, Col } from 'react-bootstrap';
/* Constants */
import {
    ABOUT_US
} from '../../constants';

const About = () => {
    return (
        <section className="about">
            <Container>
                <Row>
                    <Col className="text-center">
                        <h1>{ABOUT_US}</h1>
                        <p>二十多年來堅持誠信原則，以高品質的服務帶給客戶無後顧之憂。</p>
                        <p>本公司擁有專業技術人員18位，龐大的專業防水施工陣容與迅速效率之團隊。</p>
                        <p>本公司承做北台灣之中大型新建工程及舊房舍之整修與工廠、大樓屋頂防水翻修 信譽卓著有口皆碑。</p>
                        <p>公司各幹部皆領有國家防水技術證照。</p>
                        <p>我們提供專業人員<span>免費</span>為您估算設計、諮詢服務。</p>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default About;