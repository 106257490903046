import React, { Fragment } from 'react'
/* React Redux */
import { useSelector } from 'react-redux';
/* Components */
import Banner from './banner';
import About from './about';
import Service from './service';
import License from './license';
/* Hook */
import CheckError from '../../hook/useCheckErrorMsg';
/* CSS */
import './home.css'
/* Constants */
import {
    BANNER,
    SERVICE,
    LICENSE
} from '../../constants';

const Home = () => {
    const { banners, services, licenses } = useSelector(state => state);
    return (
        <Fragment>
            <CheckError title={BANNER} data={banners} className="bg-blue text-white">
                <Banner data={banners} />
            </CheckError>
            <About />
            <CheckError title={SERVICE} data={services} className="bg-blue text-white">
                <Service data={services} />
            </CheckError>
            <CheckError title={LICENSE} data={licenses} className="text-blue">
                <License data={licenses} />
            </CheckError>
        </Fragment>
    )
}

export default Home;
