import React from 'react';
/* React Bootstrap */
import { Container, Row } from 'react-bootstrap';
/* CSS */
import './error-msg.css';

const ErrorMsg = ({ className, title, text }) => {
    return (
        <Container fluid className={`error-msg ${className}`}>
            <Row>
                <h1>{title}</h1>
            </Row>
            <Row>
                <p>{text}</p>
            </Row>
        </Container>
    )
}

export default ErrorMsg;