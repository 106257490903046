import React from 'react';
/* React Router */
import { useHistory, useParams } from 'react-router-dom';
/* React Bootstrap */
import { Container, Row, Col } from 'react-bootstrap';
/* Components */
import ServiceBreadcrumb from '../service-breadcrumb';
import ServiceList from './service-list';
import CaseList from './list';
/* Hook */
import FilterCases from '../../../hook/useFilterCases';
import CheckError from '../../../hook/useCheckError';
import CheckErrors from '../../../hook/useCheckErrorMsg';
/* Constants */
import {
    ALL_CASE,
    SERVICE,
    CASE
} from '../../../constants';

const CaseHome = ({ services, cases }) => {
    //console.log("Case -> CaseHome");
    const history = useHistory();
    let { category } = useParams();
    const { company, filter, data } = FilterCases(services, cases, category);
    const handleGetDetail = (id) => history.push(`/case/detail/${id}`);

    return (
        <section className="case">
            <CheckErrors title={CASE} data={services} className="text-blue">
                <Container>
                    <ServiceBreadcrumb company={company} filter={filter} />
                    <Row>
                        <Col lg={3} md={0} className="d-none d-lg-block">
                            <CheckError title={SERVICE} data={services}>
                                <ServiceList data={services} />
                            </CheckError>
                        </Col>
                        <Col lg={9} md={12} >
                            <Row noGutters className="case-title">
                                <Col>
                                <h1>{(filter.name)?filter.name:ALL_CASE}</h1>
                                </Col>
                            </Row>
                            <CheckErrors title={CASE} data={services}>
                                <CaseList data={data} handleClick={handleGetDetail} />
                            </CheckErrors>
                        </Col>
                    </Row>
                </Container>
            </CheckErrors>
        </section>
    )
}

export default CaseHome;