import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
/* react-router-dom */
import { BrowserRouter as Router } from 'react-router-dom'
/* react-redux */
import { Provider } from 'react-redux';
import store from './redux/store';
/* CSS */
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-responsive-carousel/lib/styles/carousel.css'

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router>
                <App />
            </Router>
        </Provider>
    </React.StrictMode>,
  document.getElementById('root')
);