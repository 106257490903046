import React, { useState } from 'react';
/* React Bootstrap */
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
/* Common Components */
import Jumbotrons from '../../components/Jumbotrons';
import ContactInfo from '../../components/ContactInfo';
/* API */
import { DashBoardApiFactory } from "../../api/DashBoardApiFactory";
/* CSS */
import './contact.css';
/* Assets */
import jumbotronImg from '../../assets/jumbotron3.jpg';
/* Constants */
import {
    CONTACT,
    NAME,
    EMAIL,
    CONTACT_TELEPHONE,
    QUESTION_OR_SUGGEST,
    INPUT_NAME,
    INPUT_EMAIL,
    INPUT_CONTACT_TELEPHONE,
    INPUT_QUESTION_OR_SUGGEST,
    SEND_MESSAGE
} from '../../constants'

const sendMessage = (values) => {
    const {UserName, E_mail, Phone, Content} = values;
    const params = {
        name: UserName,
        email: E_mail,
        phone: Phone,
        content: Content
    };
    DashBoardApiFactory().insertMessage(params);
};

const Contact = () => {
    const [UserName, setUserName] = useState('');
    const [E_mail, setE_mail] = useState('');
    const [Phone, setPhone] = useState('');
    const [Content, setContent] = useState('');
    return(
        <section className="contact">
            <Jumbotrons page={CONTACT} img={jumbotronImg} />
            <Container>
                <Row noGutters className="justify-content-center p-3">
                    <Col lg={12} className="mb-5">
                        <iframe
                            title="sunhouse-google-map"
                            className="google-map"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3616.664106669074!2d121.33510551456173!3d24.97754028399885!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34681e96a19ee195%3A0x38ef7943970c7610!2z57i95ZCI6Ziy5rC05bel56iL5pyJ6ZmQ5YWs5Y-4!5e0!3m2!1szh-TW!2stw!4v1580809223395!5m2!1szh-TW!2stw"
                        ></iframe>
                    </Col>
                    <Col lg={6} className="mb-5">
                        <Form className="pr-5" onSubmit={() => {
                          if (E_mail === '' && Phone === '') {
                            alert('電話或信箱請擇一留下聯絡方式');
                          } else {
                            sendMessage({UserName, E_mail, Phone, Content}).then(() => {
                              alert('已送出訊息，請等候聯絡。')
                            })
                          }
                        }} >
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>{NAME}</Form.Label>
                                <Form.Control type="text" placeholder={INPUT_NAME} onChange={e => setUserName(e.target.value)}/>
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>{EMAIL}</Form.Label>
                                <Form.Control type="email" placeholder={INPUT_EMAIL} onChange={e => setE_mail(e.target.value)} />
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>{CONTACT_TELEPHONE}</Form.Label>
                                <Form.Control type="text" placeholder={INPUT_CONTACT_TELEPHONE} onChange={e => setPhone(e.target.value)} />
                            </Form.Group>

                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Label>{QUESTION_OR_SUGGEST}</Form.Label>
                                <Form.Control as="textarea" rows="3" placeholder={INPUT_QUESTION_OR_SUGGEST} onChange={e => setContent(e.target.value)} />
                            </Form.Group>
                            
                            <Button variant="lightblue" type="submit">{SEND_MESSAGE}</Button>
                        </Form>
                    </Col>
                    <Col lg={6}>
                        <ContactInfo />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Contact;