import React from 'react';
/* React-Router */
import { Link } from 'react-router-dom';
/* Hook */
import CheckError from '../../hook/useCheckError';

const SubMenu = ({ mobile, path, company, data, handleClick }) => {
    return (
        <li className={`${(mobile)?'':'dropdown-sub-content-hover'}`}>
            <Link
                // to={{ pathname: path, search: `?category=${data.id}` }}
                to={`${path}/categories/${data.id}`}
                className="dropdown-link"
                onClick={handleClick}
            >{company}
            </Link>
            <CheckError data={data.services}>
                <ul className={`${(mobile)?'':'dropdown-sub-content'}`}>
                    {(data.services).map((item, index) => {
                        return (
                            <li key={index}>
                                <Link
                                    // to={{ pathname: path, search: `?category=${item.value}` }}
                                    to={`${path}/categories/${item.value}`}
                                    className="sub-dropdown-link"
                                    onClick={handleClick}
                                >{item.name}
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </CheckError>
        </li>
    )
}

export default SubMenu;