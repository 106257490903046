/*
    API 方法入口

    說明 ex. getBanner
    async getBanner(data) {                                 <-----  getBanner為 API方法名稱。   data為傳入參數，格式為 { key1:value1 ,key2:value2 ...}
      return ApiProxy.post({                                <-----  ApiProxy.post 為呼叫 http基底js的 post方法
        path: "/api/common/getBanner",                      <-----  post位置路徑
        data: data                                          <-----  post的參數
      }).then(data => {                                     <-----  接收Response， data = Response的資料
        return data;                                        <-----  處理Response的資料， 此處是直接將 Response的資料回傳至前端
      }).catch(err => {                                     <-----  監聽 exception 錯誤
        return {Status:0, meg:err.name+':'+err.message};    <-----  exception 錯誤處理
      });
    }

 */

import ApiProxyFactory from "./ApiProxy";
import {apiHost} from "./Config";

const DashBoardApiFactory = () => {
  const ApiProxy = new ApiProxyFactory(apiHost);
  return {
    async getBannerData(data) {
      return ApiProxy.post({
        path: "/api/web/getBannerData",
        data: {}
      }).then(data => {
        if (data.Status === 1) {
          return data.ResponseData;
        } else {
          alert(data.Msg);
          return [];
        }
      }).catch(err => {
        console.log('Debug', err.name, ':', err.message);
        return {Status: 0, meg: err.name + ':' + err.message};
      });
    },

    async getLicenseData(data) {
      return ApiProxy.post({
        path: "/api/web/getLicenseData",
        data: {}
      }).then(data => {
        if (data.Status === 1) {
          return data.ResponseData;
        } else {
          alert(data.Msg);
          return [];
        }
      }).catch(err => {
        console.log('Debug', err.name, ':', err.message);
        return {Status: 0, meg: err.name + ':' + err.message};
      });
    },

    async getServiceData(data) {
      return ApiProxy.post({
        path: "/api/web/getServiceData",
        data: {}
      }).then(data => {
        if (data.Status === 1) {
          return data.ResponseData;
        } else {
          alert(data.Msg);
          return [];
        }
      }).catch(err => {
        console.log('Debug', err.name, ':', err.message);
        return {Status: 0, meg: err.name + ':' + err.message};
      });
    },

    async getCaseData(data) {
      return ApiProxy.post({
        path: "/api/web/getCaseData",
        data: {}
      }).then(data => {
        if (data.Status === 1) {
          return data.ResponseData;
        } else {
          alert(data.Msg);
          return [];
        }
      }).catch(err => {
        console.log('Debug', err.name, ':', err.message);
        return {Status: 0, meg: err.name + ':' + err.message};
      });
    },

    async insertMessage(data) {
      return ApiProxy.post({
        path: "/api/web/insertMessage",
        data: data
      }).then(data => {
        return data;
      }).catch(err => {
        console.log('Debug', err.name, ':', err.message);
        return {Status: 0, meg: err.name + ':' + err.message};
      });
    },

  };
};


export {DashBoardApiFactory};
