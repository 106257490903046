import React, { Fragment } from 'react';
/* React Router */
import { Switch, Route, useRouteMatch } from 'react-router-dom';
/* React Redux */
import { useSelector } from 'react-redux';
/* Components */
import Jumbotrons from '../../components/Jumbotrons';
/* Page */
import CaseHome from './CaseHome';
import CaseDetail from './CaseDetail';
/* CSS */
import './case.css';
/* Assets */
import jumbotronImg from '../../assets/jumbotron1.jpg';
/* Constants */
import { CASE } from '../../constants';

const Case = () => {
    const { path } = useRouteMatch();
    const { services, cases } = useSelector(state => state);
    return (
        <Fragment>
            <Jumbotrons page={CASE} img={jumbotronImg} />
            <Switch>
                <Route path={`${path}/categories/:category`}>
                    <CaseHome services={services} cases={cases} />
                </Route>
                <Route path={`${path}/detail/:id`}>
                    <CaseDetail cases={cases} />
                </Route>
            </Switch>
        </Fragment>
    )
}

export default Case;