import { 
    SET_BANNERS, 
    SET_LICENSES, 
    SET_SERVICES, 
    SET_CASES
} from './action-type';

export const SetBanner = (data) => {
    return {
        type: SET_BANNERS,
        payload: data
    }
};

export const SetLicenses = (data) => {
    return {
        type: SET_LICENSES,
        payload: data
    }
};

export const SetServices = (data) => {
    return {
        type: SET_SERVICES,
        payload: data
    }
};

export const SetCases = (data) => {
    return {
        type: SET_CASES,
        payload: data
    }
};