import React from 'react';
/* Common Components */
import IconButton from '../IconButton';
/* Hook */
import useScrollToTop from '../../hook/useScrollToTop';
/* React Fontawesome */
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
/* CSS */
import './scroll-to-top-button.css';

const ScrollTopButton = () => {
    const showScroll = useScrollToTop();
    const handleScrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
    return (
        <IconButton
            variant="scroll-to-top"
            className={`floating ${(showScroll)?'':'scroll-none'}`}
            onClick={handleScrollToTop}
            icon={faAngleUp}
        />
    )
}

export default ScrollTopButton;