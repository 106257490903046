import React from 'react';
/* React Bootstrap */
import { Container, Row, Jumbotron } from 'react-bootstrap';
/* CSS */
import './jumbotrons.css';
/* Constants */
import { HOME } from '../../constants';

const Jumbotrons = ({ page, img }) => {
    return (
        <Container fluid className="jumbotrons banner-height" style={{ backgroundImage:`url(${img})` }}>
            <Row>
                <Jumbotron>
                    <h1>{page}</h1>
                    <p>{`${HOME} / ${page}`}</p>
                </Jumbotron>
            </Row>
        </Container>
    )
}

export default Jumbotrons;