import React, { Fragment, useEffect } from 'react';
/* React Router */
import { Switch, Route } from 'react-router-dom'
/* React Redux */
import { useDispatch, useSelector } from 'react-redux';
import { SetBanner, SetLicenses, SetServices, SetCases } from './redux/Actions';
/* Pages */
import NotFound from './pages/NotFound';
/* Common Components */
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTopButton from './components/ScrollToTopButton';
/* API */
import { DashBoardApiFactory } from "./api/DashBoardApiFactory";
/* Hooks */
import OnTop from './hook/useOnTop'
/* Assets */
import './App.css'
/* Router Config */
import Routes from './Routes'

const App = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        const FetchData = async () => {
            await DashBoardApiFactory().getBannerData({}).then(response => dispatch(SetBanner(response)));
            await DashBoardApiFactory().getLicenseData({}).then(response => dispatch(SetLicenses(response)));
            await DashBoardApiFactory().getServiceData({}).then(response => dispatch(SetServices(response)));
            await DashBoardApiFactory().getCaseData({}).then(response => dispatch(SetCases(response)));
        };
        FetchData();
    }, [dispatch]);
    const { services } = useSelector(state => state);

    return (
        <Fragment>
            <Header data={services} />
            <OnTop>
                <Switch>
                    {Routes.map((item,i)=>(
                        <Route 
                            key={i}
                            exact={item.exact}
                            path={item.path}
                            component={item.component} 
                        />
                    ))}
                    <Route path="*" component={NotFound} />
                </Switch>
            </OnTop>
            <Footer />
            <ScrollToTopButton />
        </Fragment>
    )
}

export default App;