import React from 'react'
/* React Bootstrap */
import { Container, Row, Col, Card } from 'react-bootstrap'
/* Constants */
import { LICENSE } from '../../constants';

const License = ({ data }) => {
    return (
        <section className="license">
            <Container>
                <Row>
                    <Col md={12} className="text-center mb-3">
                        <h1>{LICENSE}</h1>
                    </Col>
                    {data.map((item, i) => (
                        <Col lg={4} md={6} xs={12} key={i}>
                            <Card className="text-center shadow bg-white rounded mb-5">
                                <div className="img-wrap">
                                    <Card.Img variant="top" src={item.img} />
                                </div>
                                <Card.Body className="border-top">
                                    <Card.Title className="m-0">{item.name}</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    )
}

export default License