/*
    // if data.length === 0 表示查詢不到資料
    // if data.Status === 0 表示查詢出現錯誤
*/
import { SET_LICENSES } from '../Actions/action-type';
const initState = [];

const Licenses = (state = initState, action) => {
    switch (action.type) {
        case SET_LICENSES: {
            const data = action.payload;
            return data;
        }
        default:
            return state;
    }
};

export default Licenses;