import { SET_SERVICES } from '../Actions/action-type';
const initState = [];

const Services = (state = initState, action) => {
    switch (action.type) {
        case SET_SERVICES: {
            const data = action.payload;
            return data;
        }
        default:
            return state;
    }
};

export default Services;