/*
    功能：篩選工程實例的資料
*/
import { useState, useEffect } from 'react';
import { 
    ALL_CASE_PATH,
    COMPANY_SHORT_FORM_1,
    COMPANY_SHORT_FORM_2
} from '../constants';

const useFilterCases = (services, cases, category) => {
    const [company, setCompany] = useState({});
    const [filter, setFilter] = useState({});
    const [data, setData] = useState([]);

    useEffect(()=>{
        switch(category){
            case ALL_CASE_PATH:
                setData(cases);
                break;
            case "1":
                // 總合防水工程水
                setFilter({ id: 1, name: COMPANY_SHORT_FORM_1 });
                if(cases.length !== 0){
                    setData(cases.filter((item) => item.companyId === category));
                }
                break;
            case "2":
                // 旭成室內裝修
                setFilter({ id: 2, name: COMPANY_SHORT_FORM_2 });
                if(cases.length !== 0){
                    setData(cases.filter((item) => item.companyId === category));
                }
                break;
            default:
                // 服務項目
                if(services.length !== 0){
                    services.map((item)=>{
                        if(item.value === category){
                            setCompany({ id: item.code, name: item.company });
                            setFilter({ id: item.value, name: item.name });
                            if(cases.length !== 0){
                                setData(cases.filter((item) => item.serviceId === category));
                            }
                        }
                        return false;
                    })
                }
                break;
        }
    },[services, cases, category])
    
    return { company, filter, data };
}

export default useFilterCases;