/*
    // if data.length === 0 表示查詢不到資料
    // if data.Status === 0 表示查詢出現錯誤
*/
import { SET_BANNERS } from '../Actions/action-type';
const initState = [];

const Banners = (state = initState, action) => {
    switch (action.type) {
        case SET_BANNERS: {
            const data = action.payload;  
            return data;
        }
        default:
            return state;
    }
};

export default Banners;