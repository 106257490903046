import React from 'react';
/* React Bootstrap */
import { Row } from 'react-bootstrap';

const NotFound = () => {
    return (
        <Row className="justify-content-center align-items-center pt-3 pb-5">
            <h3>查無此工程實例!</h3>
        </Row>
    )
}

export default NotFound;