/* Page */
import Home from './pages/Home';
import Case from './pages/Case';
import Question from './pages/Question';
import Contact from './pages/Contact';
/* Constant */
import { 
    HOME,
    CASE,
    QUESTION,
    CONTACT
} from './constants';

const Routes = [
    {
        exact: true,
        path: "/",
        name: HOME,
        component: Home
    },
    {
        exact: false,
        path: "/case",
        name: CASE,
        component: Case
    },
    {
        exact: false,
        path: "/question",
        name: QUESTION,
        component: Question
    },
    {
        exact: false,
        path: "/contact",
        name: CONTACT,
        component: Contact
    }
]

export default Routes;