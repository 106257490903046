/*
    功能：檢查首頁的資料是否為空或錯誤
*/
import React from 'react';
import ErrorMsg from '../components/ErrorMsg';

const useCheckErrorMsg = ({ children, title, data, className }) => {
    if(data.Status === 0){
        // 錯誤
        return <ErrorMsg className={className} title={title} text="讀取資料發生錯誤." />
    }else if(data.length === 0){
        // 空值
        return <ErrorMsg className={className} title={title} text="查無資料." />
    }else{
        return children;
    }
};

export default useCheckErrorMsg;