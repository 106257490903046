import React from 'react';
/* React Bootstrap */
import { Image } from 'react-bootstrap';
/* Common Components */
import IconButton from '../IconButton';
/* React Fontawesome */
import { faLine, faFacebookF } from '@fortawesome/free-brands-svg-icons';
/* Constants */
import { 
    CONTACT_PERSON,
    TELEPHONE,
    PHONE,
    FAX,
    EMAIL,
    ADDRESS,
    OWNER_NAME,
    OWNER_TELEPHONE,
    OWNER_PHONE,
    OWNER_FAX,
    OWNER_EMAIL,
    OWNER_ADDRESS,
    LINE_URL,
    FACEBOOK_URL,
    ADD_FRIEND,
    JOIN_FANS_PAGE
} from '../../constants'
/* Assets */
import QrCodeImg from '../../assets/qrcode.png';

const ContactInfo = ({ className }) => {
    return (
        <div className={className}>
            <p>{CONTACT_PERSON}：{OWNER_NAME}</p>
            <p>{TELEPHONE}：{OWNER_TELEPHONE}</p>
            <p>{PHONE}：{OWNER_PHONE}</p>
            <p>{FAX}：{OWNER_FAX}</p>
            <p>{EMAIL}：{OWNER_EMAIL}</p>
            <p>{ADDRESS}：{OWNER_ADDRESS}</p>
            <p>
                <IconButton 
                    variant="facebook"
                    className="social-button mr-3"
                    href={FACEBOOK_URL}
                    target="_blank"
                    icon={faFacebookF}
                    text={JOIN_FANS_PAGE}
                />
                <IconButton 
                    variant="line"
                    className="social-button"
                    href={LINE_URL}
                    target="_blank"
                    icon={faLine}
                    text={ADD_FRIEND}
                />
            </p>
            <p><Image src={QrCodeImg} height="150" alt="sunhouse-waterproof-line-qrcode" /></p>
        </div>
    )
}

export default ContactInfo;