/*
    功能：檢查資料是否為空
*/
import React, { Fragment } from 'react';
const useCheckError = ({ children, data }) => {

    if((!data) || data.length === 0 || data.Status ===0) return <Fragment></Fragment>;
    return children;
};

export default useCheckError;