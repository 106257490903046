import React from 'react';
/* React Bootstrap */
import { Row, Col, Table } from 'react-bootstrap'
/* React Responsive Carousel */
import { Carousel } from 'react-responsive-carousel';
/* Constants */
import { 
    COMPANY_NAME_2,
    CASE_NAME,
    CASE_TIME,
    CASE_AREA,
    WARRANTY,
    BUILDING_STYLE,
    BUILDING_AREA,
    BUILDING_PATTERN
} from '../../../constants';

const CaseTable = ({ data }) => {
    // console.log("CaseDetail -> CaseTable = ", data)
    return (
        <Row className="justify-content-center p-3">
            <Col lg={12}>
                <Carousel className="textCarousel" infiniteLoop={true}>
                    {(data.img).map((item, index) => (
                        <div key={index}>
                            <img src={item.src} alt={item.content} />
                            <p className="legend2">{item.content}</p>
                        </div>
                    ))}
                </Carousel>
                <Table borderless className="case-table">
                    <tbody>
                        <tr>
                            <td className="td-head">{CASE_NAME}</td>
                            <td>{data.name}</td>
                        </tr>
                        <tr>
                            <td className="td-head">{CASE_TIME}</td>
                            <td>{data.time}</td>
                        </tr>
                        <tr>
                            <td className="td-head">{CASE_AREA}</td>
                            <td>{data.area}</td>
                        </tr>
                        <tr>
                            <td className="td-head">{WARRANTY}</td>
                            <td>{data.warranty}</td>
                        </tr>
                        {(data.company===COMPANY_NAME_2)?(
                            <>
                                <tr>
                                    <td className="td-head">{BUILDING_STYLE}</td>
                                    <td>{data.buildingStyle}</td>
                                </tr>
                                <tr>
                                    <td className="td-head">{BUILDING_AREA}</td>
                                    <td>{data.buildingArea}</td>
                                </tr>
                                <tr>
                                    <td className="td-head">{BUILDING_PATTERN}</td>
                                    <td>{data.buildingPattern}</td>
                                </tr>
                            </>
                        ):null}
                    </tbody>
                </Table>
            </Col>
        </Row>
    )
}

export default CaseTable;