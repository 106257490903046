import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
/* Common Components */
import ContactInfo from '../ContactInfo';
/* Assets */
import './footer.css'
/* Constants */
import { CONTACT, COPYRIGHT } from '../../constants'

const Footer = () => {
    return (
        <footer className="footer">
            <Container className="py-3">
                <Row className="justify-content-center p-3 text-center">
                    <Col lg={12}>
                        <h3>{CONTACT}</h3>
                        <hr />
                        <ContactInfo className="footer-contact" />
                    </Col>
                </Row>
            </Container>
            <Container fluid className="copyright">
                <Row className="justify-content-center">
                    <Col lg={12}>
                        {COPYRIGHT}
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer;