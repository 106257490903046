import FormattedDate from '../../hook/useFormattedDate';
import { SET_CASES } from '../Actions/action-type';
const initState = [];

const Cases = (state = initState, action) => {
    switch (action.type) {
        case SET_CASES: {
            const data = action.payload;
            if(data.Status === 0){
                return data;
            }else{
                const newData = data.map((item)=>{
                    return {
                        ...item,
                        time: FormattedDate(item.time)
                    }
                })
                return newData;
            }   
        }
        default:
            return state;
    }
};

export default Cases;