import React from 'react';
/* React Bootstrap */
import { Container, Row, Col } from 'react-bootstrap';
/* Hook */
import FilterService from '../../hook/useFilterService';
/* Constants */
import {
    SERVICE,
    COMPANY_NAME_1,
    COMPANY_NAME_2
} from '../../constants';

const Service = ({ data }) => {
    // console.log("Home -> Service = ", data);
    return (
        <section className="service">
            <Container>
                <Row>
                    <Col md={12} className="text-center mb-3">
                        <h1>{SERVICE}</h1>
                    </Col>
                    <Col md={6} className="left">
                        <h3>{COMPANY_NAME_1}</h3>
                        <hr />
                        {(FilterService(data, COMPANY_NAME_1).services).map((item, index)=>(
                            <p key={index}>{item.name}</p>
                        ))}
                        {/* <p>防水：屋頂、水箱、中庭、廁所</p>
                        <p>隔熱：鐵皮屋、屋頂</p>
                        <p>地坪：環氧樹脂、自平泥、FRP</p> */}
                    </Col>
                    <Col md={6} className="right">
                        <h3>{COMPANY_NAME_2}</h3>
                        <hr />
                        {(FilterService(data, COMPANY_NAME_2).services).map((item, index)=>(
                            <p key={index}>{item.name}</p>
                        ))}
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Service;