/*
    功能：判斷是否顯示置頂按鈕
*/
import { useState, useEffect } from 'react';

const useScrollToTop = () => {
    const [showScroll, setShowScroll] = useState(false)
    useEffect(() => {
        const handleScroll = () => {
            const position = 300;
            let curPosition = window.pageYOffset;
            if(!showScroll && curPosition > position){
                setShowScroll(true);
            }else if (showScroll && curPosition <= position){
                setShowScroll(false);
            }
        }
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [showScroll]);
    return showScroll;
};

export default useScrollToTop;