/*
    Http 基底方法
    post、get、put、delete
 */

const universalFetch = (function getCorrectFetchImpl() {
  try {
    return fetch;
  } catch (ex) {
    return require("node-fetch");
  }
})();

class _ApiProxy {
  //設定Http的表頭
  _commonHeader = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  _apiToken = null;

  //初始串連API路徑
  constructor(apiHost) {
    this.apiHost = apiHost;
  }

  //組合完整api的http位置
  _buildEndpoint = ({path}) => {
    return path ? `${this.apiHost}${path}` : null;
  };

  //組合完整的http表頭
  _buildHeaders = ({extraHeaders}) => {
    let authHeader = this._apiToken
        ? {Authorization: `Token ${this._apiToken}`}
        : {};
    return {
      ...this._commonHeader,
      ...authHeader
    };
  };

  //設定token時使用
  setToken(token) {
    this._apiToken = token;
  }

  get({path, extraHeaders = {}}) {
    return universalFetch(this._buildEndpoint({path}), {
      method: "GET",
      headers: this._buildHeaders({extraHeaders})
    }).then(response => {
      if (`${response.status}` === "200") {
        return response.json();
      }
      return Promise.reject({status: response.status});
    });
  }

  post({path, extraHeaders = {}, data}) {
    // console.group("API Request API:", path);
    // console.log("API Path=", this._buildEndpoint({path}));
    // console.log("API Params=", data);
    // console.groupEnd();
    return universalFetch(this._buildEndpoint({path}), {
      method: "POST",
      headers: this._buildHeaders({extraHeaders}),
      body: JSON.stringify(data)
    }).then(response => response.json())
        .then(responseData => {
          // console.group("API Response API:", path);
          // console.log("API responseData=", responseData);
          // console.groupEnd();
          return responseData;
        }).catch((err) => {
          // console.group("API Exception API:", path);
          // console.error('API Exception Error:', err);
          // console.groupEnd();
          throw err;
        });
  }

  put({path, extraHeaders = {}, data}) {
    return universalFetch(this._buildEndpoint({path}), {
      method: "PUT",
      headers: this._buildHeaders({extraHeaders}),
      body: JSON.stringify(data)
    }).then(response => {
      if (`${response.status}`[0] === "2") {
        return response.json();
      }
      return Promise.reject({status: response.status});
    });
  }

  delete({path, extraHeaders = {}}) {
    return universalFetch(this._buildEndpoint({path}), {
      method: "DELETE",
      headers: this._buildHeaders({extraHeaders})
    }).then(response => {
      if (`${response.status}`[0] === "2") {
        return response.json().catch(ex => null);
      }
      return Promise.reject({status: response.status});
    });
  }

}

export default _ApiProxy;


