/*
    功能：篩選服務項目的資料 > 1 = 總和防水工程, 2 = 旭程室內裝修
*/
import { COMPANY_NAME_1, COMPANY_NAME_2 } from '../constants';

const useFilterService = (data, filter) => {
    if(data.Status === 0){
        return data;
    }

    switch(filter){
        case COMPANY_NAME_1:
            return { id: 1, services: data.filter((item) => item.code === 1) };
        case COMPANY_NAME_2:
            return { id: 2, services: data.filter((item) => item.code === 2) };
        default:
            return data;
    }
}

export default useFilterService;