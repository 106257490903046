import React, { useState } from 'react';
/* React Bootstrap */
import { Container, Row, Col } from 'react-bootstrap';
/* Components */
import Jumbotrons from '../../components/Jumbotrons';
import Accordion from './accordion';
/* CSS */
import './question.css';
/* Assets */
import jumbotronImg from '../../assets/jumbotron2.jpg';
/* Constants */
import { 
    QUESTION,
    COMPANY_SHORT_FORM_1,
    // COMPANY_SHORT_FORM_2,
    QUESTION_DATA
} from '../../constants'

export default function Question(){
    const [currentKey, setCurrentKey] = useState(null);
    const handleToggle = (key) => {
        if(currentKey === key) return setCurrentKey(null); 
        setCurrentKey(key);
    }

    return (
        <section className="question">
            <Jumbotrons page={QUESTION} img={jumbotronImg} />
            <Container>
                <Row className="title">
                    <h1>{COMPANY_SHORT_FORM_1}</h1>
                </Row>
                <Row className="justify-content-center">
                    <Col lg={9}>
                        <Accordion data={QUESTION_DATA} currentKey={currentKey} handleToggle={handleToggle} />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}