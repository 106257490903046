import React from 'react';
/* React Router */
import { useParams } from 'react-router-dom';
/* React Bootstrap */
import { Container } from 'react-bootstrap';
/* Components */
import ServiceBreadcrumb from '../service-breadcrumb';
import CaseTable from './table';
import NotFound from './not-found';
/* Hook */
import useFilterCase from '../../../hook/useFilterCase';

const CaseDetail = ({ cases }) => {
    let { id } = useParams();
    // 取得 工程實例的詳細資料
    const { company, filter, data } = useFilterCase(cases, Number(id));
    return (
        <section className="case-detail">
            <Container>
                <ServiceBreadcrumb company={company} filter={filter} />
                {(data)?<CaseTable data={data} />:<NotFound />}
            </Container>
        </section>
    )
}

export default CaseDetail;