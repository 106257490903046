
/*
    功能：換頁置頂
*/
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useOnTop = ({ children }) => {
    const { pathname } = useLocation();
    useEffect(()=>{
        window.scrollTo({ top: 0, left: 0 });
    },[pathname]);
    return children;
};

export default useOnTop;