/*
    功能：篩選工程實例的資料
*/
import { useState, useEffect } from 'react';
import { 
    COMPANY_SHORT_FORM_1,
    COMPANY_SHORT_FORM_2
} from '../constants';

const useFilterCase = (cases, id) => {
    const [company, setCompany] = useState({});
    const [filter, setFilter] = useState({});
    const [data, setData] = useState();

    useEffect(()=>{
        if(cases.length !== 0){
            cases.map((item)=>{
                if(item.id === id){
                    let companyShortForm = (item.companyId==="1")?COMPANY_SHORT_FORM_1:COMPANY_SHORT_FORM_2;
                    setCompany({ id: item.companyId, name: companyShortForm });
                    setFilter({ id: item.serviceId, name: item.service });
                    setData({
                        id: item.id,
                        img: item.img,
                        companyId: item.companyId,
                        company: item.company,
                        serviceId: item.serviceId,
                        service: item.service,
                        name: item.name,
                        time: item.time,
                        area: item.area,
                        warrenty: item.warrenty
                    });
                }
                return false;
            })
        }
    },[cases, id])

    return { company, filter, data };
}

export default useFilterCase;