/* PATH */
export const ALL_CASE = '所有工程實例';
export const ALL_CASE_PATH = 'all-case';
export const COMPANY_PATH_1 = 'tsung-he';
export const COMPANY_PATH_2 = 'hsu-cheng';
/* Owner Info */
export const OWNER_NAME = '詹森迪';
export const OWNER_TELEPHONE = '0929-930-689';
export const OWNER_PHONE = '02-2678-9416';
export const OWNER_FAX = '02-2678-2941';
export const OWNER_EMAIL = 'ray800711@gmail.com';
export const OWNER_ADDRESS = '239 新北市鶯歌區鎮朝陽街46巷2號';
/* Company 1 Info */
export const COMPANY_SHORT_FORM_1 = '總合防水工程';
export const COMPANY_NAME_1 = '總合防水工程有限公司';
/* Company 2 Info */
export const COMPANY_SHORT_FORM_2 = '旭成室內裝修';
export const COMPANY_NAME_2 = '旭成室內裝修工程行';
/* Home */
export const HOME = '首頁'
export const BANNER = '廣告條';
export const BANNER_TEXT_1 = [
    { name: '專業施工' },
    { name: '品質優先' },
    { name: '多年信譽' },
    { name: '有口皆碑' }
]
export const BANNER_TEXT_2 = [
    { name: '合格認證' },
    { name: '防水保固' },
    { name: '團隊合作' },
    { name: '永續經營' }
]
export const FREE = "免費"
export const HOME_SERVICE = "到府估價"
export const ABOUT_US = "關於我們"
export const ABOUT_US_CONTENT = "二十多年來堅持誠信原則，以高品質的服務帶給客戶無後顧之憂。本公司擁有專業技術人員18位，龐大的專業防水施工陣容與迅速效率之團隊。公司教育員工皆以客戶立場及角度施工，以求做到盡善盡美給客戶賓至如歸。本公司承做北台灣之中大型新建工程及舊房舍之整修與工廠、大樓屋頂防水翻修 信譽卓著有口皆碑。公司各幹部皆領有國家防水技術證照，我們提供專業人員，免費 為您估算設計、諮詢服務。"
export const SERVICE = "服務項目"
export const LICENSE = "證照"
/* Csee */
export const CASE = '工程實例'
export const TOTAL_DATA1 = '共有：';
export const TOTAL_DATA2 = ' 筆資料';
/* QUESTION */
export const QUESTION = '常見問題'
export const QUESTION_DATA = [
    {
        company: "總合防水工程",
        question: "窗邊漏水、脫漆1",
        answer: "1.窗戶設計不當、五金配件或止水片有瑕疵。\n2.窗邊崁縫不確實。"
    },
    {
        company: "總合防水工程",
        question: "冷氣窗，鋁窗窗台裂縫所造成漏水",
        answer: "剪力裂縫  開口處裂縫。(即窗下45'角裂縫)"
    },
    {
        company: "總合防水工程",
        question: "緊鄰浴缸之臥室牆壁脫漆，發霉",
        answer: "1.牆角防水不確實。\n2.防水材料選用不當或施工不確實。"
    },
    {
        company: "總合防水工程",
        question: "風頭壁面嚴重壁癌，油漆屢刷無效",
        answer: "1.加牆磚造外牆防水粉刷不良。\n2.外牆結構性裂縫。\n3.其他原因。"
    },
    {
        company: "總合防水工程",
        question: "客聽、臥室踢腳部位之漏水",
        answer: "1.外露樑表面積水。\n2.外牆層間接縫沒作防水。"
    },
    {
        company: "總合防水工程",
        question: "頂樓柱子週邊漏水、脫漆",
        answer: "1.屋頂排水不通或洩水不良。\n2.屋頂落水頭週邊防水處理不當。"
    },
    {
        company: "總合防水工程",
        question: "頂樓天花板龜裂(尤其是BOX附近滴水)",
        answer: "1.屋頂結構龜裂。\n2.屋頂防水施工不佳或防水材料選用不當。"
    },
    {
        company: "總合防水工程",
        question: "屋頂汎水壓磚下方及客聽大門上方漏水",
        answer: "1.女兒牆施工縫處理不當。\n2.風厝地坪隅角防水處理不當。"
    },
    {
        company: "總合防水工程",
        question: "屋頂管道間或排風口下方牆面滴水",
        answer: "1.隅角防水不良。\n2.防水施工程序不當。"
    },
    {
        company: "總合防水工程",
        question: "地下一樓天花板裂縫漏水",
        answer: "1.中庭結構性裂縫。\n2.中庭防水材料選用不當或防水施工不良。"
    }
]
/* Contact */
export const CONTACT = '聯絡我們'
export const NAME = "姓名"
export const CONTACT_PERSON = "聯絡人"
export const CONTACT_TELEPHONE = "聯絡電話"
export const TELEPHONE = "手機"
export const PHONE = "電話"
export const FAX = "傳真"
export const EMAIL = "E-mail"
export const ADDRESS = "地址"
export const MAP = "地圖"
export const FOLLOW_AND_ADD = "追蹤加好友"
export const QUESTION_OR_SUGGEST = "問題或建議"
export const INPUT_NAME = "輸入您的姓名"
export const INPUT_EMAIL = "輸入您的電子信箱"
export const INPUT_CONTACT_TELEPHONE = "輸入您的聯絡電話或手機號碼"
export const INPUT_QUESTION_OR_SUGGEST = "輸入您的問題或建議"
export const SEND_MESSAGE = "送出訊息"
/* Case */
export const CASE_NAME = "工程名稱"
export const CASE_TIME = "工程時間"
export const CASE_ITEM = "工程項目"
export const CASE_AREA = "工程地區"
export const WARRANTY = "保固年限"
export const BUILDING_STYLE = "建築風格"
export const BUILDING_AREA = "建築面積"
export const BUILDING_PATTERN = "室內格局"
/* Copyright */
export const COPYRIGHT = 'Copyright 2021 © ' + COMPANY_NAME_1
/* Other */
export const SERVICE_CATEGORY = "服務項目分類"
export const ALL = "全部"


/* Components / ContactInfo */
export const ADD_FRIEND = '加入好友'
export const JOIN_FANS_PAGE = '加入粉絲團';
/* URL */
export const FACEBOOK_URL = 'https://www.facebook.com/總合防水旭成土木包工業旭成室內設計工程-792106804459714';
export const LINE_URL = 'https://lin.ee/4UHIuW54l';
export const GOOGLE_MAP_URL = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3616.664106669074!2d121.33510551456173!3d24.97754028399885!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34681e96a19ee195%3A0x38ef7943970c7610!2z57i95ZCI6Ziy5rC05bel56iL5pyJ6ZmQ5YWs5Y-4!5e0!3m2!1szh-TW!2stw!4v1580809223395!5m2!1szh-TW!2stw';

export const a='TSUNG-HE 總合   waterproof 防水    Insulation 隔熱  Floor 地坪'
export const b='HSU-CHENG 旭成  decorate 室內設計裝修  Suite planning  套房規劃  demolition  拆除' 