import React from 'react';
/* React Router */
import { Link } from 'react-router-dom';
/* Hook */
import FilterService from '../../../hook/useFilterService';
/* Constants */
import {
    COMPANY_NAME_1,
    COMPANY_NAME_2,
    COMPANY_SHORT_FORM_1,
    COMPANY_SHORT_FORM_2
} from '../../../constants';

const ServiceList = ({ data }) => {
    return (
        <ul className="category">
            <li>
                <Link to="/case/categories/1">
                    {COMPANY_SHORT_FORM_1}
                </Link>
            </li>
            <ul className="sub-category">
                {(FilterService(data, COMPANY_NAME_1).services).map((item, index) => {
                    return (
                        <li key={index}>
                            <Link to={`/case/categories/${item.value}`}>
                                {item.name}
                            </Link>
                        </li>
                    )
                })}
            </ul>
            <li>
                <Link to="/case/categories/2">
                    {COMPANY_SHORT_FORM_2}
                </Link>
            </li>
            <ul className="sub-category">
                {(FilterService(data, COMPANY_NAME_2).services).map((item, index) => {
                    return (
                        <li key={index}>
                            <Link to={`/case/categories/${item.value}`}>
                                {item.name}
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </ul>
    )
}

export default ServiceList;