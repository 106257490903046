import React from 'react';
/* React-Router */
import { NavLink } from 'react-router-dom';
import Routes from '../../Routes';
/* Components */
import SubMenu from './sub-menu';
/* Hook */
import CheckError from '../../hook/useCheckError';
import FilterService from '../../hook/useFilterService';
/* Constants */
import { 
    CASE,
    ALL_CASE_PATH,
    COMPANY_NAME_1,
    COMPANY_NAME_2,
    COMPANY_SHORT_FORM_1,
    COMPANY_SHORT_FORM_2
} from '../../constants';

const Menu = ({ className, mobile, data, handleClick }) => {
    return (
        <nav className={className}>
            <ul>
                {Routes.map((item, index) => (
                    <li key={index} className={`${(item.name===CASE)?'dropdown-content-hover':''}`}>
                        <NavLink
                            exact={item.exact}
                            to={(item.name===CASE)?`${item.path}/categories/${ALL_CASE_PATH}`:item.path}
                            // to={item.path}
                            className="menu-link"
                            activeClassName="menu-active"
                            onClick={handleClick}
                        >{item.name}
                        </NavLink>
                        {(item.name===CASE)?
                            <CheckError data={data}>
                                <ul className={`nav-sub-menu ${(mobile)?'':'dropdown-content'}`}>
                                
                                    {/* 總合防水工程 */}
                                    <SubMenu 
                                        mobile={mobile}
                                        path={item.path}
                                        company={COMPANY_SHORT_FORM_1}
                                        data={FilterService(data, COMPANY_NAME_1)}
                                        handleClick={handleClick}
                                    />
                                    {/* 旭成室內裝修 */}
                                    <SubMenu 
                                        mobile={mobile}
                                        path={item.path}
                                        company={COMPANY_SHORT_FORM_2}
                                        data={FilterService(data, COMPANY_NAME_2)}
                                        handleClick={handleClick}
                                    />
                                </ul>
                            </CheckError>
                        :''}
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default Menu;