import React from 'react';
/* React Bootstrap */
import { Container, Row, Col, Image } from 'react-bootstrap';
/* React Responsive Carousel */
import { Carousel } from 'react-responsive-carousel';
/* Constants */
import {
    FREE,
    HOME_SERVICE,
    OWNER_NAME,
    OWNER_TELEPHONE,
    BANNER_TEXT_1,
    BANNER_TEXT_2
} from '../../constants'

const Banner = ({ data }) => {
    return (
        <section className="banner">
            <Container fluid className="p-0">
                <Row noGutters>
                    <Col lg={12}>
                        <Carousel showStatus={false} showThumbs={false} infiniteLoop={true}>
                            {data.map((item,i) => (
                                <div key={i}>
                                    <Image 
                                        src={item.img} 
                                        className="banner-height"
                                        alt="sunhouse-waterproof-banner" 
                                    />
                                    <Container fluid className="carousel-text p-0">
                                        <Row noGutters className="justify-content-end">
                                            <Col className="carousel-text-left rounded-left">
                                                <div className="bg-orange rounded h-100 d-flex align-items-center justify-content-center">
                                                    <div>
                                                        <h4 className="mb-1">{FREE}</h4>
                                                        <h4 className="mt-1">{HOME_SERVICE}</h4>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col className="carousel-text-right text-left">
                                                <div className="h-100">
                                                    <h3 className="text-orange ml-1">{OWNER_NAME}<span className="ml-2">{OWNER_TELEPHONE}</span></h3>
                                                    <p>
                                                        {BANNER_TEXT_1.map((item, index)=>(
                                                            <span className="mx-1" key={index}>{item.name}</span>
                                                        ))}
                                                    </p>
                                                    <p>
                                                        {BANNER_TEXT_2.map((item, index)=>(
                                                            <span className="mx-1" key={index}>{item.name}</span>
                                                        ))}
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            ))}
                        </Carousel>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Banner;